<!-- <ng-container *ngIf="overlay;else progressSpinner"> -->
<ng-container *ngIf="msgService.isProcessing">
  <div class="show-spinner">
    <div class="center">
      <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
    </div>
  </div>
</ng-container>
<ng-template #progressSpinner>
  <mat-progress-spinner [mode]="mode" [color]="color" [strokeWidth]="strokeWidth" [value]="value">
  </mat-progress-spinner>
</ng-template>
