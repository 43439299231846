@if (accountService.getUserLogin())
{
  <mat-toolbar color="primary" class="app-nav-bar">
    <button mat-icon-button (click)="openMenuDialog()">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="app-spacer"></div>
    {{menuService.currentMenuTitle()}}
    <app-account [username]="accountService.getUserLogin()!"
      (isLogOut)="signOut($event)">
    </app-account>
  </mat-toolbar>
}
