import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, mergeMap, Observable, take } from 'rxjs';
import { Auth } from "@angular/fire/auth";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: Auth) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //let clone = req.clone()
    let token = from(this.authService.currentUser?.getIdToken()!);
    return token.pipe(
      take(1),
      mergeMap((idToken: any)  => {
        let clone = req.clone()
        if (idToken) {
          clone = clone.clone({ headers: req.headers.set('Authorization', 'Bearer ' + idToken!) });
        }
        return next.handle(clone)
      })
    )


  }
}
