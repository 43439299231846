<form [formGroup]="stateForm">
  <mat-form-field>
    <mat-label>Tìm nhanh (ví dụ: don hang ban)</mat-label>
    <input type="text" tabindex="-1"
           matInput
           formControlName="stateGroup"
           [matAutocomplete]="autoGroup">
      <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="selectItem($event)">
        @for (group of mnuGroupOptions | async; track group) {
          <mat-optgroup [label]="group.mgrItem!.mgrName!">
            @for (item of group.childMenus; track item) {
              <mat-option [value]="item" >{{item.mnuName}}</mat-option>
            }
          </mat-optgroup>
        }
    </mat-autocomplete>
  </mat-form-field>
</form>